@import 'variable';

.App, body, #root{
    
    // background-color: $primitive-color;
    background: linear-gradient(-45deg, rgb(159, 22, 75), #791443,#AD1C5F);
    background-size: 400% 400%;
    // animation: gradient 0s ease-out infinite forwards;
    color: $secondary-color;
    width:100%;
    height: 100%;

}

#Header{

    display:block;
    color : white;//perfomance in class
    font-size: 1.8rem;
    height : 50rem;
    animation: 80s infinite alternate linear forwards backgroundChange; 
    background-position: bottom;
    background-size : cover; 

    h1{
        user-select: none;
        margin: auto;
        padding: 10px;
        position : relative;
        top: 40%;
        font-weight: map-get($map: $weight, $key: bold );
        text-shadow: $text-shadow;
        animation: textfloat 6s ease-in-out infinite;
    }

}


#Menu{

    .Nav {
        display:block;
        position : fixed;
        top:0;
        z-index: 1;
        width : 100%;
        height : 70px;
        background-color: $grey-color;
        @include backgroundcolor_animation();
        font-size: .8rem;
        margin : 0;

        ul {

            display:flex;
            height : 100px;
            padding : 0 10%;
            justify-content: center;

            li {
                display : block;
                padding : 2.5% 0;
                margin : 0 2.6em;
                width : 5em;

                :hover{

                    color:$primitive-color;
                    @include transition-ease();
                    text-shadow: $text-shadow;

                }
            }
        
            .home{
                position : relative;
                display: block;
                padding : 2.5% 0;
                margin : 0 2.6em;
                padding: 0;
                z-index :3;
                height : 80px;
                width :160px;
                background-image : url(../Img/logo/main_home_logo.svg);
                background-repeat: no-repeat;
                background-size: 150px;
                background-position: center;

                span{
                    visibility: hidden;
                    display: block;//make the link to home
                    width : 100%;
                    height : 100%;
                }

            }

        }
    }
}


#Main{

    display:block;
    height : 30rem;
    font-weight: map-get($map: $weight, $key: bold );

    h1 {
        font-size: 1.3rem;
        padding: 2rem;
        color: white;
    }

    p{
        font-size: .8rem;
        line-height: 1.2rem;
        font-weight : map-get($map: $weight, $key: medium);
            b {
            strong {
                color: white;
            }
        }
    }

}



#Button{

    .MeetTheCarde{
        display:block;
        width: 10rem;
        background-color:  #791443;
        font-size: .9rem;
        margin : auto;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        z-index: 0;

        :hover {
            background-color: #AD1C5F;
            @include transition-ease();
        }
    
    }

    .button_link{
        display: block;
        padding : .9rem .3rem;
        color: white;
        width: auto;
        height :auto;
    }

}

#SNS{

    display: flex;
    height : 100px;
    position: fixed;
    bottom: -40px;
    left : 20px;
    animation: textfloat 6s ease-in-out infinite; 

    :hover{
        color: #FF94C6;
        @include transition-ease();
    }
    
    a {
        padding : 10px;
    }

}


#Article{

    height : 600px;
    margin : 1% 10%;
    
    &_list{ //ul

        display: flex;
        justify-content: space-around;

        .lists{ //li
            a{
                display : block;
                .logo{
                    object-fit: contain;
                    width:100%;
                }
            }
    
            &.active{
                .logo{
                    animation: float 2s ease-in-out infinite; 
                }
                .title{
                    color: $secondary-color;
                }
                .description{
                    font-size : 1rem;
                    color:$secondary-color;
                    display: block;
                    position : absolute;
                    left: 0; 
                    right: 0; 
                    margin-top: 5rem;
                    margin-left: auto; 
                    margin-right: auto; 
                    width: 50%;
                    transition: all 1s ease-in-out;
                }
            }
            
            &.inactive{
                :hover{
                    .title{
                        color:$secondary-color !important; // make it appear
                        transition: all .5s ease-in;
                    }
                }
                .logo{
                    object-fit: contain;
                    width:100%;
                }
                .title{
                    color: transparent;
                }
                .description{
                    display : none;
                }
            }
        }
    }
}


#Footer{

    height : 11rem;
    background-color : $primitive-color;

    h2{
        visibility: hidden;
    }

    .contactlists{
        padding : 1rem;
        font-size: .9rem;
        display : flex;
        height : 3rem;
        a{
            color : $secondary-color;

        }
        li{
            margin : auto;
            width : 300px;
        }
        .logo{
            background-image : url(../Img/logo/main_home_logo.svg);
            background-repeat: no-repeat;
            background-size: 120px;
            background-position: center;
            margin : 0;
            width : 170px;
            @include hide-text;
        }
    }

    .Footer_Copyright{
        font-size: .8rem;
        width : 80%;
        margin : auto;
        border-top: 1px $secondary-color solid;
        display : relative;
        top : 5rem;
        padding : .5rem;
    }
}


@import 'mobile';