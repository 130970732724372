@media screen and (max-width : 768px){
#Menu{
    .menu-btn{
        position: fixed;
        z-index: 2;
        left :1rem;
        top :2rem;
        height :20px;
        width : 28px;
        cursor: pointer;

        
        .menu-burger,
        .menu-burger::before,
        .menu-burger::after{
            display: block;
            background-color: #fff;
            position: absolute;
            height: .23rem;
            width:1.7rem;
            border-radius: 2px;
        }
        .menu-burger::before{
            margin-top: -8px;
            content: '';

        }
        .menu-burger::after{
            margin-top: 8px;
            content: '';
        }
    }
    
    .Nav{
        z-index : 1;
        background-color: $grey-color;
        animation : none;
        &.inactive{
            color: transparent;
            width : 100%;
            height : 70px;
            li{
                visibility: hidden;
            }
            .home{
                visibility: visible;
                position: fixed;
                top : 4px;
                margin : auto;     
                pointer-events: none;      
            }
        }
        &.active{
            
            background-color: $grey-color;
            color: white;
            transition: background-color .6s ease-in-out;
            height : 100%;
            width : 100%;

            ul{
                display : block;
                width:100%;
                height: 100%;
                padding : 0;
                li{
                    border-bottom :solid 1px $secondary-color;
                    padding :10%;
                    font-size: 2em;
                    margin : auto;

                }
            }
            .home{
                position : static;
                background-image: none;
                width: 5rem;
                height: auto;
                margin:auto;
                span{
                    visibility: visible;
                }
                
            }
        }
    }
    
}
#Main{
    height : 43rem;
    p{
        padding : 2%;
    }
}
#Article{
    padding : 5rem 0;
    line-height : 1.2rem;
    &_list{
        .lists{
            .logo{
                width : 8rem;
            }
            &.active{
                .title{
                    position: absolute;
                    margin-top : 2rem;
                    left : 0;
                    right :0;
                }
                
                .description{
                    font-size : .8rem;
                    width: 80%;
                }
            }
            &.inactive{
                .title{
                    position : absolute;
                }
            }
        }
    }
}

#SNS{
    visibility: hidden;
}

#Footer{
    height : 20rem;
    .contactlists{
        height : 10rem;
        flex-direction: column;
    }
    
}
}
// @media screen and (max-width : 415px){
//     #Main{
//         font-size: 0.2rem;
//         display: none;
//     }
// }